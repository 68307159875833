import { Component, Mixins } from "vue-property-decorator";

import RefillMixin from "../../common/mixins/refill.mixin";

import PaymentMethods from "./components/PaymentMethods/PaymentMethods.vue";

@Component({
  components: {
    PaymentMethods,
  },
})
export default class DesktopRefill extends Mixins(RefillMixin) {}
